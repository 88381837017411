<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
            <h1 class="h3">{{ $t('chan-pin') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-10 justify-content-center">
      <div class="col-md-4 mx-auto mb-3" >
        <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
          <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
            <i class="las la-upload la-2x text-white"></i>
          </span>
          <div class="px-3 pt-3 pb-3">
            <div class="h4 fw-700 text-center">{{goodsLimit}}</div>
            <div class="opacity-50 text-center">{{ $t('sheng-yu-shang-chuan') }}</div>
          </div>
        </div>
      </div>

      <div class="col-md-4 mx-auto mb-3" >
        <a @click="toPath('productStorehouse')">
          <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
            <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
              <i class="las la-plus la-3x text-white"></i>
            </span>
            <div class="fs-18 text-primary">{{ $t('tian-jia-xin-chan-pin') }}</div>
          </div>
        </a>
      </div>


      <div class="col-md-4">
        <a class="text-center bg-white shadow-sm hov-shadow-lg text-center d-block p-3 rounded">
          <img :src="packageInfo.url" height="44" class="mw-100 mx-auto" v-if="hasPackage">
          <span class="d-block sub-title mb-2" v-if="hasPackage">{{ $t('dang-qian-tao-can') }} {{packageInfo.name}}</span>

          <i class="la la-frown-o mb-2 la-3x" v-if="!hasPackage"></i>
          <div class="d-block sub-title mb-2" v-if="!hasPackage">{{ $t('mei-you-tao-can') }}</div>

          <div class="btn btn-outline-primary py-1" @click="toPath('sellerPackage')">{{ $t('sheng-ji-tao-can') }}</div>
        </a>
      </div>
    </div>

    <div class="card">
      <div class="card-header row gutters-5">
        <div class="col">
            <h5 class="mb-md-0 h6">{{ $t('suo-you-chan-pin') }}</h5>
        </div>
        <div class="col-md-4 text-right">
          <div class="btn btn-outline-primary py-1" @click="patchUp">{{ $t('pi-liang-shang-jia') }}</div>&nbsp;&nbsp;
          <div class="btn btn-outline-primary py-1" @click="patchDown">{{ $t('pi-liang-xia-jia') }}</div>
        </div>
        <div class="col-md-4">
          <form class="" >
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" id="search" :placeholder="$t('sou-suo-chan-pin')">
            </div>
          </form>
        </div>
      </div>
      <div class="card-body">
        <table class="table mb-0" :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>
                <Checkbox shape="square" v-model="allChecked" @click="changeAll"></Checkbox>
              </th>
              <th>#</th>
              <th data-breakpoints="md">
                {{ $t('suo-lve-tu') }} </th>
              <th width="30%">{{ $t('ming-cheng') }}</th>
              <th data-breakpoints="md">{{ $t('lei-bie') }}</th>
              <th data-breakpoints="md" width="100px">{{ $t('dang-qian-shu-liang') }}</th>
              <th>{{ $t('jia-ge') }}</th>
              <th data-breakpoints="md">{{ $t('shen-he') }}</th>
              <th data-breakpoints="md">{{ $t('fa-bu') }}</th>
              <th data-breakpoints="md">{{ $t('jing-xuan') }}</th>
              <th data-breakpoints="md">{{ $t('zhi-tong-che-tui-guang') }}</th>
              <th data-breakpoints="md" class="text-right">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>
                <Checkbox shape="square" v-model="item.checked" @click="changeItem(item)"></Checkbox>
              </td>
              <td>{{ item.ID }}</td>
              <td>
                <img height="80px" class="lazyload"  :src="item.ImgUrl" key="">
              </td>
              <td>
                <a class="text-reset">{{item.GoodsName}}</a>
              </td>
              <td>
                {{item.CommodityClassification ? item.CommodityClassification.Name : ''}}
              </td>
              <td>
                {{item.Stock}}
              </td>
              <td>{{ resetPrice(item.Price) }}</td>
              <td>
<!--                <span class="badge badge-inline badge-success" v-if="item.status == 1">{{ $t('yi-shen-he') }}</span>-->
<!--                <span class="badge badge-inline badge-info" v-else>{{ $t('shen-he-zhong') }}</span>-->
                <span class="badge badge-inline badge-success">{{ $t('yi-shen-he') }}</span>

              </td>
              <td>
                <label class="aiz-switch aiz-switch-success mb-0">
                  <input  v-model="item.Shelves" type="checkbox"  @change="e => changeStatus('Shelves', item)">
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <label class="aiz-switch aiz-switch-success mb-0">
                  <input  type="checkbox" v-model="item.Featured" @change="e => changeStatus('Featured', item)">
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <label class="aiz-switch aiz-switch-success mb-0">
                  <input type="checkbox"  v-model="item.ThroughTrain" @change="e => changeStatus('ThroughTrain', item)">
                  <span class="slider round"></span>
                </label>
              </td>
              <td class="text-right">
                <a class="btn btn-soft-info btn-icon btn-circle btn-sm"  :title="$t('bian-ji')" @click="toEdit(item)">
                  <i class="las la-edit"></i>
                </a>
                <a  class="btn btn-soft-danger btn-icon btn-circle btn-sm confirm-delete" :title="$t('shan-chu')" @click="removeData(item)">
                  <i class="las la-trash"></i>
                </a>
                </td>
              </tr>
              <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="11">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { productList, editProduct, removeProduct } from '@/api/seller'
import pageBar from '@/components/page'
import { Checkbox } from 'vant'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Checkbox
  },
  data() {
    return {
      form: {
        title: ''
      },
      allChecked: false,
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      packageObj: {
        level1: require('../../../assets/imgs/package.png'),
        level2: require('../../../assets/imgs/package2.png'),
        level3: require('../../../assets/imgs/package3.png'),
      },
      tableData: [],
      checkedList: [],
      page: {
        page: 1,
        limit: 10,
        total: 1,
        totalRecord: 0
      },
      packageInfo: {
        url:  require('../../../assets/imgs/package.png'),
      },
      goodsLimit: 0,
      resultCount: 0
    }
  },
  computed: {
    shopInfo() {
      return this.$store.state.shopInfo
    }
  },
  watch: {
    shopInfo(val) {
      if (val.ID) {
        this.checkInfo()
      }
    }
  },
  mounted() {
    this.init()
    if (this.shopInfo.ID) {
      this.checkInfo()
    }
  },
  methods: {
    resetPrice,
    checkInfo() {
      if (this.shopInfo.ShopLevelID) {
        this.hasPackage = true
        this.packageInfo.url = this.packageObj[`level${this.shopInfo.ShopLevel}`] || this.packageUrl
        this.packageInfo.name = this.shopInfo.ShopLevel.LevelName
        this.goodsLimit = this.shopInfo.ShopLevel.GoodsLimit - this.page.totalRecord
      } else {
        this.hasPackage = false
      }
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      form.append('title', this.form.title)
      productList(form).then(res => {
        this.tableData = res.data.Items.map(v => {
          v.Shelves = v.Shelves == 1
          v.Featured = v.Featured == 1
          v.ThroughTrain = v.ThroughTrain == 1
          return v
        })
        this.page.total = res.data.Pagination.totalPage
        this.page.totalRecord = res.data.Pagination.totalRecords
        if (this.shopInfo.ShopLevelID) {
          this.goodsLimit = this.shopInfo.ShopLevel.GoodsLimit - this.page.totalRecord
        }
      })
    },
    changePage(page) {
      this.page.page = page
      this.init()
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    changeStatus(key, data, isPatch) {
      let form = new FormData()
      form.append('ID', data.ID)
      form.append('CategoryId', data.CategoryId)
      form.append('GoodsName', data.GoodsName)
      form.append('ImgUrl', data.ImgUrl)
      form.append('ImgUrls', data.ImgUrls)
      form.append('Price', data.Price)
      form.append('Attribute', data.Attribute)
      form.append('Stock', data.Stock)
      form.append('Featured', data.Featured ? 1 : 2)
      form.append('Shelves', data.Shelves ? 1 : 2)
      form.append('ThroughTrain', data.ThroughTrain ? 1 : 2)
      editProduct(form).then(res => {
        if (res.code == 0) {
          if (!isPatch) {
            this.$toast.success(this.$t('qie-huan-cheng-gong'))
          } else {
            this.resultCount += 1
            this.checkResult()
          }
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    checkResult() {
      if (this.checkedList.length == this.resultCount) {
        this.$toast.success(this.$t('pi-liang-xiu-gai-cheng-gong'))
        this.checkedList = []
        this.allChecked = false
        this.tableData.forEach((v, i) => {
          this.$set(this.tableData[i], 'checked', false)
        })
      }
    },
    removeData(data) {
      let form = new FormData()
      form.append('ID', data.ID)
      removeProduct(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('shan-chu-cheng-gong-0'))
          this.page.pgae = 1
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    toEdit(data) {
      sessionStorage.setItem('productData', JSON.stringify(data))
      this.$router.push({
        name: 'editProduct',
        query: {
          id: data.ID
        }
      })
    },
    changeAll(val) {
      if (val) {
        this.checkedList = [].concat(this.tableData)
        this.tableData.forEach((v, i) => {
          this.$set(this.tableData[i], 'checked', true)
        })
      } else {
        this.tableData.forEach((v, i) => {
          this.$set(this.tableData[i], 'checked', false)
        })
        this.checkedList = []
      }
    },
    changeItem(data) {
      let val = data.checked
      if (val) {
        this.checkedList.push(data)
        if (this.checkedList.length == this.tableData.length) {
          this.allChecked = true
        }
      } else {
        this.allChecked = false
        let index = this.checkedList.findIndex(v => {
          return v.ID == data.ID
        })
        if (index > -1) {
          this.checkedList.splice(index, 1)
        }
      }
    },
    patchUp() {
      if (this.checkedList.length == 0) {
        this.$toast.fail(this.$t('qing-xuan-ze-shu-ju'))
        return
      }
      this.resultCount = 0
      this.checkedList.forEach(v => {
        v.Shelves = true
        this.changeStatus('Shelves', v, true)
      })
    },
    patchDown() {
      if (this.checkedList.length == 0) {
        this.$toast.fail(this.$t('qing-xuan-ze-shu-ju-0'))
        return
      }
      this.resultCount = 0
      this.checkedList.forEach(v => {
        v.Shelves = false
        this.changeStatus('Shelves', v, true)
      })
    }
  }
}
</script>